











import { Component } from 'vue-property-decorator';
import ProfileCompanyComponent from '@/components/admin-panel/ProfileCompanyComponent.vue';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { ProfileCompanyComponent },
})
export default class CompanyCmsProfileHeaderWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget) {
  created(): void {
    this.setDataConfig();
  }
}
